import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { useStyles } from './styles';
import Header from '../Header/Header';

interface BaseScreenWithBackgroundProps {
		children: ReactNode | ReactNode[];
		withHeader?: boolean;
		headerProps?: ComponentPropsWithoutRef<typeof Header>
		className?: string;
		backgroundImage?: 'gradient' | 'stripeGradient';
}

const BaseScreenWithBackground = ( {
	className,
	withHeader = false,
	headerProps,
	children,
	backgroundImage = 'gradient',
}: BaseScreenWithBackgroundProps ) => {
	const { classes, cx } = useStyles( { backgroundImage } );

	return (
		<>
			{withHeader && <Header {...headerProps} />}
			<main className={cx(
				classes.container,
				withHeader
					? classes.withHeader
					: classes.withoutHeader,
				className,
			)}
			>
				{children}
			</main>
		</>
	);
};

export default BaseScreenWithBackground;
